.top-bar {
  background-color: #1f3b55;
  color: white;
}

.icon {
  color: orange;
  margin-left: 10px; /* Add spacing between icons */
  margin-right: 10px;
}

.contact-info span,
.social-icons span {
  margin-right: 15px; /* Add spacing between text items */
  font-size: 0.9rem;
  
}
.contact-info .icon,
.social-icons .icon {
  margin-left: 25px; /* Slight margin for better spacing */
  font-size: 17px;
}
/* Navbar Background and Brand Icon */
.custom-navbar {
  background-color: white;
}

.brand-icon {
  color: #1f3b55;
  font-size: 1.7rem;
}

.brand-title {
  color: orange;
  font-size: 1.35rem;
  font-weight: bold;
}

.brand-subtitle {
  color: #1f3b55;
  font-size: 0.75rem;
  font-weight: bold;
}

/* Navigation Links */
.nav-link {
  color: #1f3b55;
  font-weight: 500;
  margin-right: 15px;
  transition: color 0.3s ease;
  cursor: pointer;
}

.nav-link:hover {
  color: orange;
}

/* Appointment Button */
.btn-appointment {
  background-color: orange !important;
  color: white !important;
  border: none !important;
  font-weight: bold ;
  padding: 8px 15px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.btn-appointment:hover {
  background-color: #e69500;
}
/* home page */
.slider {
  position: relative;
  z-index: 1;
}

/* Services Section Styling */
.services-section {
  margin-top: -150px; /* Adjust this value for the overlap */
  position: relative;
  z-index: 2;
}

.service-card {
  border: 2px solid #ddd;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  transition: transform 0.3s ease-in-out;
}

.service-card:hover {
  transform: translateY(-10px);
}

/* Background Colors for Cards */
.service-card-blue {
  background-color: #1f3b55 !important;
}

.service-card-orange {
  background-color: orange !important;
}

/* expertise section */
/* Hero Section */
.hero-section {
  background-image: url("/public/images/installing-a-water-heater-money.jpg");
  background-size: cover;
  background-position: center;
  height: 400px;
  position: relative;
}

.hero-section .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(31, 59, 85, 0.8);
 
}

.hero-section .hero-content {
  position: relative;
  z-index: 2;
  color: #fff;
  padding: 50px 20px;
  text-align: left; /* Align text to the left */
}

.hero-section h2 {
  font-size: 20px;
  color: #e69500;
  margin-bottom: 10px;
}

.hero-section h1 {
  font-size: 32px;
  margin-bottom: 20px;
  font-weight: bolder;
}

.hero-section p {
  font-size: 14px;
  line-height: 1.5;
  margin-top: 15px;
  max-width: 600px;
  color: #b8b6b6;
}
/* Services Section */
.expertise-section{
  margin-top: -100px; /* Adjust this value for the overlap */
  position: relative;
  z-index: 2;
 
  border-radius: 8px;
  border-radius: 10px;
  text-align: center;
  transition: transform 0.3s ease-in-out;
}
.equal-card {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
  transition: transform 0.3s ease;
}

.equal-card:hover {
  transform: translateY(-5px);
}

.services-img {
  width: 100%;
  height: auto; /* Set fixed height for images */
  object-fit: cover; /* Ensure images fill the area */
}

.equal-card h3 {
  font-size: 20px;
  font-weight: bolder;
  margin-top: 15px;
}

.equal-card p {
  font-size: 14px;
  color: #555;
  margin: 15px 0;
}

.equal-card .btn-primary {
  background-color: orange;
  border: none;

  transition: background-color 0.3s ease;
}

.equal-card .btn-primary:hover {
  background-color: #1f3b55;
}

/* why we better */
/* General styles for the section */
.why-we-better {
  padding: 60px 0;
  background-color: #f7f7f7;
}

.left-content h4 {
  font-size: 18px;
  color: #e69500;
  font-weight: bolder;
  margin-bottom: 10px;
  text-transform: uppercase;
}

.left-content h1 {
  font-size: 36px;
  font-weight: 00;
  color: #1f3b55;
  margin-bottom: 20px;
}

.left-content p {
  font-size: 15px;
  color: #555;
  line-height: 1.6;
  margin-bottom: 20px;
}

.features-list {
  list-style-type: none;
  padding-left: 0;
}

.features-list li {
  font-size: 15px;
  color: #1f3b55;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.features-list li::before {
  content: "✔";
  margin-right: 10px;
  color: #e69500;
}

/* Right Section */
.right-content .card {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.right-content .card:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.right-content .card .icon img {
  width: 70px;
  margin-bottom: 20px;
}

.right-content .card h3 {
  font-size: 1px;
  color: #1f3b55;
  font-weight: 600;
  margin-bottom: 15px;
}

.right-content .card p {
  font-size: 16px;
  color: #555;
  line-height: 1.6;
}

.card-body {
  padding: 30px;
  text-align: center;
}

.testimonial-section {
  background-color: #2c3e50;
  color: #fff;
  padding: 30px;
  height: auto;
  
  position: relative;
}

.testimonial-image {
  width: 80%;

  height: auto;
  object-fit: cover;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  margin: 0 auto;
  transform: translateY(-20%);
}

.image-section {
  text-align: center;
  
}



.testimonial-heading {
  color: #f39c12;
font-weight: bold;
  text-transform: uppercase;
}

.testimonial-title {
  margin-bottom: 28px;
  font-size: 3.5rem;
 
}

.testimonial-description {
  margin-bottom: 10px;
  line-height: 1.8;
  color: #bbb
  
}

.testimonial-quote {
  font-style: italic;
  font-size: 1rem;
  margin-bottom: 30px;
  margin-top: 5%;
}

.testimonial-info {
  display: flex;
  align-items: center;
  gap: 10px;
  
  margin-bottom: 20px;
}

.testimonial-image-circle {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.testimonial-name {
  margin: 0;
}

.testimonial-position {
  margin: 0;
  font-size: 0.9rem;
  color: #bbb;
}
/* Hide the dots (indicators) in the carousel */
.carousel-indicators {
  display: none !important;
}

/* Hide the arrows (prev/next) in the carousel */
.carousel-control-prev, 
.carousel-control-next {
  display: none !important;
}


/* Responsive Styles */
@media (max-width: 768px) {
  .testimonial-section {
    padding: 30px;
  }

  .testimonial-title {
    font-size: 2.5rem;
  }

  .content-section {
    margin-left: 0;
    padding: 10px;
  }

  .testimonial-description {
    font-size: 0.9rem;
  }
}

@media (max-width: 576px) {
  .testimonial-title {
    font-size: 2rem;
  }

  .testimonial-quote {
    font-size: 0.9rem;
  }

  .testimonial-description {
    font-size: 0.85rem;
  }

  .testimonial-info {
    flex-direction: column;
    align-items: flex-start;
  }

  .testimonial-image-circle {
    margin-bottom: 10px;
  }
}
/* // projects section */

/* projects section */
/* General Styling */
.projects-container {
  padding: 50px;
  text-align: center;
}

.projects-title {
  font-size: 22px;
  float: left;
  color: #e69500;
  font-weight: bolder;
  margin-bottom: 10px;
}

.projects-header {
  font-size: 30px;
  color: #1f3b55;
  font-weight: bolder;
  margin-bottom: 30px;
}
.projects-description{
  font-size: 15px;
  color: #555;

  margin-bottom: 30px;
}

/* Grid Styling */
.projects-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
}

.project-card {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
}

.project-image img {
  width: 100%;
  height: 300px;
  object-fit: cover;
  transition: transform 0.3s ease-in-out;
}

.project-card:hover img {
  transform: scale(1.1);
}

/* Hover Effect */
.project-hover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #1f3b55;
  color: orange;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.project-card:hover .project-hover {
  opacity: 1;
}

.project-hover p {
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
  margin: 0;
}
.kangpipa-section {
  position: relative;
  padding: 50px 20px;
  text-align: center;
  background-color: rgba(0, 0, 0, 0);
  color: orange;
  overflow: hidden;
}

.kangpipa-section {
  position: relative;
  z-index: 1; /* Ensures the content appears above the background */
}

.kangpipa-section::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("/public/images/background.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  z-index: -2; /* Ensures the image is below the overlay */
}

.kangpipa-section::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #1f3b55; /* Overlay color */
  opacity: 0.8; /* Adjust opacity to your preference */
  z-index: -1; /* Ensures the overlay is above the background image */
}


.section-title {
  color: white;
  text-transform: uppercase;
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.section-heading {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.section-description {
  margin-bottom: 40px;
  line-height: 1.8;
  font-size: 1.1rem;
  color: white
}
.experience-container {
  display: flex;
  justify-content: center; /* Horizontally center */
  align-items: center; /* Vertically center */
  flex-wrap: wrap; /* Wrap the elements if needed */
  gap: 20px; /* Add spacing between items */
  padding: 20px;
}

.counter-box {
  background-color: orange;
  color: #1f3b55;
  width: 200px !important;
  margin: 10px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}


.counter-box:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

.CountUp {
  font-size: 2.5rem;
 
  font-weight: bold;
}

.counter-text {
  margin-top: 10px;
  font-size: 1rem;
  font-weight: 500;
}
/* Hero Section */
/* Hero Section */
.hero-section {
  background-image: url("/public/images/installing-a-water-heater-money.jpg");
  background-size: cover;
  background-position: center;
  position: relative;
  height: 450px;
  z-index: 2;
}

.hero-content {
  position: relative;
 
  color: #fff;
  padding: 50px 20px;
}

/* Overlay */
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #1f3b55;
  opacity: 0.8;
}



.custom-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

/* Card Customization */
.card {
  border: none;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease;
  height: 100%; /* Make cards equal height */
}

.card:hover {
  transform: translateY(-10px);
}

/* Card Image */
.card .cards-img{
  height: 100px; 
  width: 150px;/* Adjust image height */
  object-fit: cover;
  margin-left: 30%; /* Maintain aspect ratio */
  border-radius: 8px 8px 0 0; /* Match card's border-radius */
}

/* Button Customization */
.card .btn {
  background-color: orange; /* Orange button */
  color: #fff;
  border: none;
  font-weight: bold;
}

.card .btn:hover {
  background-color: #1f3b55; /* Darker orange on hover */
}

/* Mobile View Adjustments */
@media (max-width: 480px) {
  .services-section {
    display: flex;
    justify-content: center;
    gap: 20px;
    position: relative;
    top: 0px !important;
    z-index: 2;
    
  }
  .expertise-section{
    margin-top: 10px; /* Adjust this value for the overlap */
    position: relative;
    
  
    border-radius: 8px;
    border-radius: 10px;
    text-align: center;
    transition: transform 0.3s ease-in-out;
  }

  /* Adjust Card Padding and Centering */
  .custom-container {
    padding: 0 15px;
  }
}

.services-section {
  display: flex;
  justify-content: center;
  gap: 20px;
  position: relative;
  top: -10px;
  z-index: 2;
}
.footer{
  background-color: #1f3b55;
 
}